.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}

@media screen and (max-width: 1200px) {
  .container {
    margin-top: 5px;
  }
}
