.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.git {
  justify-content: right;
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
  :hover {
    color: black;
  }
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
}

.navContainer {
  width: 65%;
}
.aboutContainer {
  width: 45%;
  position: relative;
}

@media screen and (max-width: 880px) {
  .navContainer,
  .aboutContainer {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .buttonContainer {
    flex-direction: column;
    a {
      margin-top: 5px !important;
    }
  }
}
