.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 5px;
}

.errorContainer {
  text-align: center;
  color: red;
  padding-bottom: 5px;
}

.scoreRow {
  p {
    display: inline-block !important;
    text-align: center !important;
    width: 160px !important;
    &#score {
      text-align: center !important;
    }
  }
}

.totalScore {
  margin-top: 10px;
  margin-bottom: 10px;
  h4 {
    display: inline-block !important;
  }
  h4:last-child {
    margin-left: 15px !important;
  }
}
