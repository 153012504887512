.container {
  width: 30%;
}

.formContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.formRow {
  display: inline-block;
  margin-top: 10px;
  label {
    display: inline-block;
    text-align: left !important;
    width: 175px !important;
    margin-right: -25px !important;
    &#noDropDown {
      margin-right: 0px !important;
      margin-bottom: 10px !important;
      :only-child {
        margin-bottom: 0px !important;
      }
    }
  }
  input {
    max-width: 50px !important;
    text-align: left !important;
    margin-top: -5px !important;
  }
}

.submitButton {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .container {
    width: 65%;
  }
}

@media screen and (max-width: 700px) {
  .container {
    width: 100%;
  }
}
